@import '../BootstrapSectra/Resources/spx-colors';

.MainTemplateList {
    position: relative;
    height: calc(100vh - 60px);
    padding: 2px 0 0;
    display: flex;
    flex-direction: column;

    --toolbox-size: 45px;
    --toolbox-background-color: rgba(0, 0, 0, .1);
    --vertical-border-color: rgba(0, 0, 0, .1);
    --horizontal-border-color: rgba(0, 0, 0, .2);

    > div {
        flex: 1;
        display: flex;
        flex-direction: row;

        > div {
            flex: 1;
        }

        > div:first-child {
            max-width: 300px;
            width: 20%;
            border-right: 1px solid var(--horizontal-border-color);
            margin-right: -1px;
        }

        > div:nth-child(2) {
            border-left: 1px solid var(--horizontal-border-color);
        }
    }

    /* main-container */
    > div:first-child {
        position: relative;
        height: calc(100% - var(--toolbox-size));

        h2 {
            margin: 0.1rem 0;
            font-size: 12px;
            line-height: normal;
            font-weight: normal;
            color: rgba(0, 0, 0, .6);
            cursor: default;
        }

        > div {
            height: 100%;    
            overflow-y: auto;
            overflow-x: hidden;
            padding: 0 5px;
        }
    }

    /* toolbox */
    > div:nth-child(2) {
        height: var(--toolbox-size);
        max-height: var(--toolbox-size);

        > div {
            padding: 5px 0 0;

            > div {
                padding: 5px 0 0 5px;
                border-top: 1px solid var(--vertical-border-color);
                height: calc(var(--toolbox-size) - 5px);
                overflow: hidden;
                background-color: var(--toolbox-background-color);
            }
        }    
    }

    .FolderAdmin {
        border-bottom: 1px solid var(--vertical-border-color);
        margin-bottom: .5rem;
        padding-bottom: .5rem;

        > div {
            margin: 0;

            label {
                font-size: 12px;
                line-height: normal;
                font-weight: normal;
            }

            input {
                padding: 2px 6px;
                height: 28px;
                font-size: 13px;
            }

            > div {
                margin-top: .6rem;
                button {
                    height: 24px;
                    padding: 3px 12px;
                }
            }
        }
    }
}

/* theme adaptations */
.theme-dark .MainTemplateList {
    --toolbox-background-color: rgba(0, 0, 0, .2);
    --vertical-border-color: rgba(255, 255, 255, .1);
    --horizontal-border-color: rgba(255, 255, 255, .2);
    --table-hover-color: $spx-color-asphalt-425;

    > div:first-child h2 {
        color: rgba(255, 255, 255, .6);
    }

    button.btn-secondary:not([disabled]):not(.disabled):active {
        border: 1px solid rgba(255, 255, 255, 0.3);
        padding-bottom: 3px;
    }
}

.theme-light .MainTemplateList {
    button.btn-secondary:not([disabled]):not(.disabled):active {
        border: 1px solid #d1d3d5;
        padding-bottom: 3px;
    }
}

.theme-dark .svgcheckbox-button {
    color: white;
    &:hover {
        color:rgba(255, 255, 255, .6);
        .svg-icon.svgcheckbox {
            fill: rgba(255, 255, 255, .6);
        }
    }
    &:disabled {
        color: #e7e8ea;
        fill: rgba(255, 255, 255, .6);
    }
    .svg-icon.svgcheckbox {
        fill: white;
    }
}

.theme-light .svgcheckbox-button {
    color: black;
    &:hover {
        color:rgba(0, 0, 0, .6);
        .scg-icon.svgcheckbox {
            fill: rgba(0, 0, 0, .6);
        }
    }
    &:disabled {
        fill: rgba(0, 0, 0, .6);
        color: rgba(0, 0, 0, .6);
    }
    .svg-icon.svgcheckbox {
        fill: black;
    }
}

.VersionsSelectAll {
    margin-right: -4px;
    font-size: 13px;
}