.autocomplete {
    position: relative;
    .input-container {
        position: relative;
        & input {
            cursor: pointer;
        }
        & .svg-icon {
            position: absolute;
            right: 8px;
            top: 50%;
            translate: 0 -50%;
            cursor: pointer;
            &.clear:hover {
                opacity: .5;
            }
        }
    }
    
    .list-group {
        margin-top: 2px;
    }

    .selected-item-text {
        padding-left: 4px;
    }

    .dropdown-item {
        border-radius: 0;
        border-width: 1px;
        border-bottom-width: 0px;
        text-align: left;
        width: 100%;
        padding-top: 5px;
        padding-bottom: 5px;
        // Dropdown items showing information (e.g. More search result found, keep narrowing down the search)
        &.info, &.info:hover {
            color: gray;
            display: block;
            width: 100%;
            padding: 5px;
            background-color: #263854;
        }

        &:first-child {
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
        }

        &:last-child {
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
            border-bottom-width: 1px;
        }

    }

    .input-container>.autocomplete-single-input {
        padding-right: 40px;
        text-overflow: ellipsis;
    }

    .dropdown-items-container {
        display: grid;
        position: absolute;
        min-width: 100%;
        max-width: max(100%, 25rem);
        width: max-content;
        z-index: 1;
    }

    .autocomplete-values {
        display: grid;
        grid-template-columns: 1fr;
        & .autocomplete-value-row {
            display: grid;
            grid-template-columns: minmax(0,1fr) max-content;
            column-gap: 1rem;
            padding-bottom: 1rem;
        }
    }
}

.theme-dark .dropdown-item {
    border-color: #1d2b43;
    &.info, &.info:hover {
        background-color: #263854;
    }
    &.active {
        background-color: #27446c;
    }
}

.theme-light .dropdown-item {
    border-color: gray;
    background-color: white;
    &.info, &.info:hover, &.active {
        background-color: #fff;
        border-style: solid;
    }
    &.active {
        background-color: #c8d7eb;
    }
}