@import './BootstrapSectra/Resources/spx-colors';

#auth-modal-overlay-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9999;
    width: 100%;
    height: 100%;
    padding-top: 100px;
    background-color: black;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-transition: 0.5s;
    overflow: auto;
    transition: all 0.3s linear;
    backdrop-filter: blur(1.4px);
}

#auth-modal-overlay-container > div {
    margin-top: -20px;
    display: none;
}

#auth-modal-overlay-container > div.display {
    display: block;
}

#auth-modal-overlay-container > div .alert {
    border-width: 3px;
    padding: 0;
    background-color: white;
    min-width: 500px;
}

#auth-modal-overlay-container > div .alert > h4 {
    background-color: $spx-color-yellow;
    padding: 10px;
    margin-bottom: 0;
}

#auth-modal-overlay-container > div .alert > h4 > svg {
    display: block;
    float: right;
    width: 16px;
    height: 16px;
    cursor: pointer;
}

#auth-modal-overlay-container > div .alert > p.errorMessage {
    padding: 10px;
    margin: 0;
    cursor: pointer;
}

#auth-modal-overlay-container > div .alert > p.errorMessage:empty {
    display: none;
}

#auth-modal-overlay-container > div .alert > iframe {
    border: 0;
    width: 500px;
    height: 400px;
}
