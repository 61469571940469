.help-table td {
  padding-right: 15px;
}

.box {
  padding: 10px;
}

.theme-dark .box {
  border: 1px solid white;
}

.theme-light .box {
  border: 1px solid black;
}

#toc ul {
  list-style-type: none;
}

.toc-2 {
  counter-reset: toc2;
}

.toc-2 li:before {
  counter-increment: toc2;
  content: counter(toc2) ". ";
}

.toc-3 {
  counter-reset: toc3;
}

.toc-3 li:before {
  counter-increment: toc3;
  content: counter(toc2) "." counter(toc3) ". ";
}

.toc-4 {
  counter-reset: toc4
}

.toc-4 li:before {
  counter-increment: toc4;
  content: counter(toc2) "." counter(toc3) "." counter(toc4) ". ";
}

.section-count {
  counter-reset:section;
}

.section-count h2 {
  counter-reset:subsection;
}

.section-count h2:before {
  counter-increment:section;
  content: counter(section) ". ";
  font-weight:bold;
}

.section-count h3 {
  counter-reset: subsubsection;
}

.section-count h3:before {
  counter-increment:subsection;
  content:counter(section) "." counter(subsection) " ";
}

.section-count h4:before {
  counter-increment: subsubsection;
  content: counter(section) "." counter(subsection) "." counter(subsubsection) " ";
}

.section-count h2 {
  margin-top: 50px;
}

.section-count h4 {
  margin-top: 20px;
}

#ug-contents img :not #comp-img {
  max-width: 100%;
  min-width: 800px;
}

.anchor {
  scroll-margin-top: 60px;
}

ol {
  margin-top: 20px;
}

.mono {
  font-family: 'Courier New', Courier, monospace;
}

pre {
  white-space: pre-wrap;
}

img {
  max-width: 100%;
}