@import "../../BootstrapSectra/Resources/spx-colors";

.Toolbox {
    flex: 0 0 150px;
    padding: 0 6px 0 2px;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;

    background-color: $spx-color-silver-550;
    color: $spx-color-black-500;

    .theme-dark & {
        background-color: $spx-color-asphalt-450;
        color: $spx-color-white-500;
    }

    > button {
        width: 100%;
        margin-top: 5px;
        font: normal normal 400 1em/1 "open_sans", "Segoe UI", "Trebuchet MS", Helvetica, Arial, sans-serif;

        &:first-child {
            margin-top: 3px;
        }
    }

    .undo-spec-button {
      color: $spx-color-yellow-300;
    }

    .clear-spec-button {
      color: $spx-color-red-300;
    }
}
