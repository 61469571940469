@import '../BootstrapSectra/Resources/spx-colors';

.editorTabs {
  width: 50%;
  height: 100%;
}

.nav-tabs{
  height: 39px;
}

.tab-content {
    height: calc(100% - 39px - 10px);
}

.tab-pane {
  height: 100%;
}

.clickable {
  cursor: pointer;
}

.theme-light .editorTabs{
    background-color: $spx-color-silver-500;
    color: $spx-color-black-500;
}

.theme-dark .editorTabs{
    background-color: $spx-color-asphalt-400;
    color: $spx-color-white-500;
}

.defaultMarginTop {
  margin-top: 2rem;
}

.generated-code {
  max-height: calc(100% - 7px);
  color: #000;
  background: transparent;
}

.theme-dark .generated-code {
  background-color: #2b3e50;
  color: #fafafa;
}

@media (max-width: 768px) {
  .navbar-nav {
    line-height: 10px;
  }
}

.navbar-collapse {
  background-color: $spx-color-asphalt-500;
}