@import '../../BootstrapSectra/Resources/spx-colors';

.Viewer {
    flex: 1 1;
    padding: 0 5px;
    min-width: 350px;
    overflow: auto;
}

.Viewer .tab-content {
    overflow-y: auto;
    clear: both;
}

.Viewer .tab-content > div > div.tab-page {
  height: 100%;
  overflow-y: auto;
}

.Viewer .tab-content > div > div.tab-page.preview {
  overflow: none;
  display: flex;
  flex-direction: column;
}

.error-list {
  border: 1px solid gray;
  border-radius: 4px;
  padding: 10px;
  margin: 0 5px 0 0;
  background-color: white;
  color: rgba(0,0,0,.8);
  margin-bottom: 1rem;
}

.theme-dark .error-list {
  border-color: rgba(255, 255, 255, 0.3);
  background-color:$spx-color-asphalt-325;
  color: white;
}

.error-list h2 {
  margin: 0;
  font-size: 1.6em;
}

.error-list a {
  color: inherit;
}

.error-list ul {
  padding: 1px 0 0 3px;
}

.error-list ul li {
  list-style: none;
}

div.tab-page.preview > div.preview-content {
  overflow-y: auto;
}

.theme-light .Viewer {
    background-color: $spx-color-silver-500;
    color: $spx-color-black-500;
}

.theme-dark .Viewer {
    background-color: $spx-color-asphalt-400;
    color: $spx-color-white-500;
}

.Viewer .preview-tab-toolbar {
  text-align: right;
  padding: 0 .4rem .3rem 0;
  border-bottom: 1px solid $spx-color-silver-650;
}

.Viewer .preview-tab-toolbar button {
  padding: 2px 6px 3px;
  margin-left: .3rem;
}

.theme-dark .Viewer .preview-tab-toolbar {
  text-align: right;
  border-bottom: 1px solid $spx-color-asphalt-325;
}

.srt-container .sectra-options.focused > .btn-group {
  border-radius: 3px;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .8), 0 0 6px rgba(102, 175, 233, .60);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .8), 0 0 6px rgba(102, 175, 233, .60);
}

.srt-container .sectra-options > .btn-group > .btn-secondary {
  color: #333;
  border: 1px solid #d1d3d5;
  background-color: #fefefe;
}

.srt-container .sectra-options > .btn-group:not(.btn-group-stacked) > .btn-secondary:not(:first-child) {
  border-left: none !important;
}

.srt-container .sectra-options > .btn-group > .btn-secondary:not([disabled]):not(.disabled):hover {
  background-color: #eff6ff;
  color: #000;
}
.srt-container .sectra-options > .btn-group > .btn-secondary.active,
.srt-container .sectra-options > .btn-group > .btn-secondary:active {
  padding-bottom: 2px;
  border-bottom: 2px solid #679de4;
}
.srt-container .sectra-options > .btn-group > .btn-secondary:not([disabled]):not(.disabled).active {
  color: #333;
  background-color: #fefefe;
}
.srt-container .sectra-options > .btn-group > .btn-secondary:not([disabled]):not(.disabled):active:hover {
  color: #000;
}
.srt-container .sectra-options:not(.multichoice) > .btn-group > .btn-secondary:not([disabled]):not(.disabled).active:hover {
  background-color: #fefefe;
}
.srt-container .sectra-options.multichoice > .btn-group > .btn-secondary.focus:not(.active):not([disabled]):not(.disabled) {
  background-color: #eff6ff;
}
.srt-container .sectra-options.multichoice > .btn-group > .btn-secondary.focus.active:not([disabled]):not(.disabled):not(:hover) {
  background-color: #f3f8ff;
}


.theme-dark .srt-container .sectra-options > .btn-group > .btn-secondary {
  color: rgba(255, 255, 255, .7);
  border: 1px solid #323f54;
  background-color: $spx-color-asphalt-350;
  padding-bottom: 3px;
}
.theme-dark .srt-container .sectra-options > .btn-group > .btn-secondary:not([disabled]):not(.disabled):hover {
  color: #fff;
  background-color: $spx-color-asphalt-300;
  border: 1px solid #323f54;
}
.theme-dark .srt-container .sectra-options > .btn-group > .btn-secondary.active {
  padding-bottom: 2px;
  border-bottom: 2px solid #679DE4;
}
.theme-dark .srt-container .sectra-options > .btn-group > .btn-secondary:not([disabled]):not(.disabled).active {
  color: rgba(255, 255, 255, .7);
  background-color: $spx-color-asphalt-350;
  border-bottom: 2px solid #679DE4;
}
.theme-dark .srt-container .sectra-options > .btn-group > .btn-secondary:not([disabled]):not(.disabled).active:hover {
  color: #fff;
  background-color: $spx-color-asphalt-300;
}
.theme-dark .popover-content {
 background-color: $spx-color-asphalt-350;
}
.theme-dark .popover {
  background-color: $spx-color-asphalt-400;
}
.theme-dark .popover.right>.arrow:after {
  border-right-color: $spx-color-asphalt-350;
}
.theme-dark .popover.bottom>.arrow:after {
  border-bottom-color: $spx-color-asphalt-350;
}
.theme-dark .popover.left>.arrow:after {
  border-left-color: $spx-color-asphalt-350;
}
.theme-dark .popover.top>.arrow:after {
  border-top-color: $spx-color-asphalt-350;
}
.popover-content {
  white-space: pre-wrap;
}

.glyphicon {
  border-radius: 50%;
}
.theme-dark .glyphicon {
  color: #263854;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background-color: rgba(255, 255, 255, 0.7);
}
.theme-light .glyphicon {
  color: $spx-color-silver-500;
  border: 1px solid rgba(0,0,0,.3);
  background-color: rgba(0,0,0,.7);
}

.theme-dark .srt-container .sectra-options:not(.multichoice) > .btn-group > .btn-secondary:not([disabled]):not(.disabled).active:hover {
  background-color: $spx-color-asphalt-350;
}
.theme-dark .srt-container .sectra-options.multichoice > .btn-group > .btn-secondary.focus:not(.active):not([disabled]):not(.disabled) {
  background-color: $spx-color-asphalt-300;
}
.theme-dark .srt-container .sectra-options.multichoice > .btn-group > .btn-secondary.focus.active:not([disabled]):not(.disabled):not(:hover) {
  background-color: $spx-color-asphalt-325;
}

/* list styling of options - highlight borders somewhat more against the brighter background */
.theme-dark .srt-container .sectra-component-list .sectra-options > .btn-group > .btn-secondary {
  border-color: #3b4452;
}
.theme-dark .srt-container .sectra-component-list .sectra-options > .btn-group > .btn-secondary:not(:last-child) {
  border-right-color: #323f54;
}

.btn-group.btn-fill-equal > * {
  max-width: 10px;
  display: table-cell;
}

.btn-group.btn-group-stacked {
  display: flex;
  flex-direction: column;
}

.btn-group.btn-group-stacked > .btn {
  display: block;
  width: auto;
  margin-left: 0;
  padding: 1px 5px 3px;
}

.srt-container .sectra-options > .btn-group.btn-group-stacked > .btn-secondary:not(:first-child) {
  border-top: none !important;
  padding-top: 2px;
}

.srt-container .sectra-options >.btn-group.btn-group-stacked > .btn-secondary:not([disabled]):not(.disabled).active,
.srt-container .sectra-options >.btn-group.btn-group-stacked > .btn-secondary:not([disabled]):not(.disabled):active {
  border-bottom: 1px solid #d1d3d5;
  padding-bottom: 3px;
  border-left: 5px solid #679de4;
  padding-left: 1px;
}

.theme-dark .srt-container .sectra-options > .btn-group.btn-group-stacked .btn-secondary:not([disabled]):not(.disabled).active,
.theme-dark .srt-container .sectra-options > .btn-group.btn-group-stacked .btn-secondary:not([disabled]):not(.disabled):active {
  border-bottom: 1px solid #323f54;
  padding-bottom: 3px;
  border-left: 5px solid #679de4;
  padding-left: 1px;
}

.btn-group.btn-group-stacked > .btn:not(:first-child) {
  border-top: none;
}

.btn-group.btn-group-stacked > .btn:first-child:not(:last-child) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.btn-group.btn-group-stacked > .btn:last-child:not(:first-child) {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-checkbox:not(.disabled),
.btn-radio:not(.disabled) {
  cursor: pointer;
}

.btn-checkbox > input[type='checkbox'],
.btn-radio > input[type='radio'] {
  position: absolute;
  left: -2000px;
  opacity: 0;
}

.btn-checkbox > span,
.btn-radio > span {
  display: inline;
  vertical-align: middle;
  margin-left: .3em;
  line-height: 1.4;
}

.btn-checkbox > div,
.btn-radio > div {
  display: inline-block;
  border-radius: 50%;
  border: 1px solid transparent;
  background-color: transparent;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  transition: color 0.3s;
  vertical-align: middle;
  text-align: center;
  margin: 1px 0 0 -3px;
  height: 1.7em;
  width: 1.7em;
}

.btn-checkbox > div > .svg-icon,
.btn-radio > div > .svg-icon {
  display: inline-block;
  border-radius: 0;
  font-size: 1.05rem;
  margin-bottom: 2px;
}

.btn-checkbox:not(.disabled):hover > div, 
.btn-radio:not(.disabled):hover > div {
  background-color:  rgba(150, 189, 241, 0.2);
}

.btn-checkbox.focus:not(.disabled) > div, 
.btn-checkbox.focus:not(.disabled):hover > div,
.btn-radio.focus:not(.disabled) > div, 
.btn-radio.focus:not(.disabled):hover > div {
  background-color: rgba(150, 189, 241, 0.2);
  border-color: rgba(169, 202, 246, 0.3);
}

.btn-checkbox.disabled,
.btn-radio.disabled {
  color: rgba(0, 0, 0, .4);
  cursor: not-allowed;
}

.theme-dark .btn-checkbox.disabled,
.theme-dark .btn-radio.disabled {
  color: rgba(255, 255, 255, .3);
}

.btn-checkbox.spacing-tight,
.btn-radio.spacing-tight {
  margin-bottom: -.15rem;
}
.btn-checkbox.spacing-small,
.btn-radio.spacing-small {
  margin-bottom: 0;
}

.btn-checkbox,
.btn-checkbox.spacing-normal,
.btn-radio,
.btn-radio.spacing-normal {
  margin-bottom: .2rem;
}
.btn-checkbox.spacing-airy,
.btn-radio.spacing-airy {
  margin-bottom: .5rem;
}

// NOTE: This class should not be modified here, but in backplane/TemplateInjectedScripts/Index.ts to ensure old templates works well on new systems and vice versa
.srt-container {
  width: 100%;
  overflow-x: hidden;
}

.Viewer .srt-container {
  margin-top: .7rem;
}

.srt-container .row > * > .empty {
  min-height: 24px;
}

.srt-container .spx-checkbox:before {
  font-family: Slim-Sectra-PX;
}

.srt-container > div:first-of-type {
  margin-top: 0;
}

.theme-light .srt-container select, 
.theme-light .srt-container input, 
.theme-light .srt-container textarea,
.theme-light .srt-container .textarea {
  border: 1px solid $spx-color-silver-700;
}
.theme-light .srt-container .sectra-component-list select,
.theme-light .srt-container .sectra-component-list input, 
.theme-light .srt-container .sectra-component-list textarea
.theme-light .srt-container .sectra-component-list .textarea {
  border: 1px solid $spx-color-silver-750;
}
.theme-dark .srt-container select, 
.theme-dark .srt-container input, 
.theme-dark .srt-container textarea,
.theme-dark .srt-container .textarea {
  border: 1px solid $spx-color-asphalt-400;
}

.theme-dark .srt-container .sectra-component-list select, 
.theme-dark .srt-container .sectra-component-list input, 
.theme-dark .srt-container .sectra-component-list textarea,
.theme-dark .srt-container .sectra-component-list .textarea {
  border: 1px solid $spx-color-asphalt-325;
}

.srt-container .row .container {
  width: auto;
  max-width: unset;
  min-width: unset;
}

.srt-container .row > div:nth-child(2) {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
}

.srt-container .row.nowrap > div:nth-child(2) {
  flex-wrap: nowrap;
}

.srt-container .row > div:nth-child(2) > .SectraSection {
  flex-grow: 1;
  width: 100px; /* with flex grow this more acts as a min-width indication */
}


/* handling spacing between row items (col spacing, currently .8rem after each component) */
.srt-container .row > .col-xs-1:nth-child(2)  {
  width: calc(8.33333333% + .8rem);
}
.srt-container .row > .col-xs-2:nth-child(2)  {
  width: calc(16.66666667% + .8rem);
}
.srt-container .row > .col-xs-3:nth-child(2)  {
  width: calc(25% + .8rem);
}
.srt-container .row > .col-xs-4:nth-child(2)  {
  width: calc(33.33333333% + .8rem);
}
.srt-container .row > .col-xs-5:nth-child(2)  {
  width: calc(41.66666667% + .8rem);
}
.srt-container .row > .col-xs-6:nth-child(2)  {
  width: calc(50% + .8rem);
}
.srt-container .row > .col-xs-7:nth-child(2)  {
  width: calc(58.33333333% + .8rem);
}
.srt-container .row > .col-xs-8:nth-child(2)  {
  width: calc(66.66666667% + .8rem);
}
.srt-container .row > .col-xs-9:nth-child(2)  {
  width: calc(75% + .8rem);
}
.srt-container .row > .col-xs-10:nth-child(2)  {
  width: calc(83.33333333% + .8rem);
}
.srt-container .row > .col-xs-11:nth-child(2)  {
  width: calc(91.66666667% + .8rem);
}
.srt-container .row > .col-xs-12:nth-child(2)  {
  width: calc(100% + .8rem);
}
.srt-container .row > div:nth-child(2) {
  margin-right: -.8rem;
}

.srt-container .row > div:nth-child(2) > *,
.srt-container .row > div:nth-child(2) > .SectraSection > *:not(.container) {
  margin-bottom: 1.2rem;
  margin-right: .8rem;
}

.srt-container .row > div:nth-child(2) > .SectraSection > .container {
   padding-left: 0;
   padding-right: 0;
}

/* special handling of prefix/suffix spacing (current .3rem after prefix/suffix or .15rem after normal component follow by .15 rem in suffix/prefix) */
.srt-container .row > div:nth-child(2) > .inline-text.prefix,
.srt-container .row > div:nth-child(2) > .SectraSection > .inline-text.prefix,
.srt-container .row > div:nth-child(2) > .inline-text.suffix,
.srt-container .row > div:nth-child(2) > .SectraSection > .inline-text.suffix {
  margin: 0 .3rem 1.2rem .15em;
}
.srt-container .row > div:nth-child(1) > .btn-checkbox {
  margin-bottom: 1.2rem;
}
.srt-container .row > div:nth-child(2) > .has-suffix,
.srt-container .row > div:nth-child(2) > .has-trailing-prefix {
  margin-right: .15rem;
}
/*
.srt-container .row > div:nth-child(2) > :not(.inline-text.prefix):not(.inline-text.suffix):not(.row-break) + .inline-text.prefix {
  margin-left: -.5rem;
}
*/

.srt-container .row.spacing-none > div:nth-child(2) > *,
.srt-container .row.spacing-none > div:nth-child(1) label {
  margin-bottom: 0 !important;
}

.srt-container .row.spacing-minimal > div:nth-child(2) > *,
.srt-container .row.spacing-minimal > div:nth-child(1) label {
  margin-bottom: .2rem !important;
}

.srt-container .row.spacing-tight > div:nth-child(2) > *,
.srt-container .row.spacing-tight > div:nth-child(1) label {
  margin-bottom: .5rem !important;
}

.srt-container .row.spacing-small > div:nth-child(2) > * {
  margin-bottom: .8rem !important;
}

.srt-container .row.spacing-airy > div:nth-child(2) > * {
  margin-bottom: 2rem !important;
}

.srt-container .row div:nth-child(2) > div {
  flex-grow: 1;
}
.srt-container .row div:nth-child(2) .btn-group.btn-group-xs {
  width: auto;
  flex-grow: 1;
}
.srt-container .row .input-size-custom {
  flex-grow: 0 !important;
  max-width: 100%;
}
.srt-container .row .input-size-xxs {
  flex-grow: 0 !important;
  width: 3.8rem;
  max-width: 100%;
}
.srt-container .row .input-size-xs {
  flex-grow: 0 !important;
  width: 4.5rem;
  max-width: 100%;
}
.srt-container .row .input-size-s {
  flex-grow: 0 !important;
  width: 7rem;
  max-width: 100%;
}
.srt-container .row .input-size-m {
  flex-grow: 0 !important;
  width: 10rem;
  max-width: 100%;
}
.srt-container .row .input-size-l {
  flex-grow: 0 !important;
  width: 15rem;
  max-width: 100%;
}
.srt-container .row .input-size-xl {
  flex-grow: 0 !important;
  width: 25rem;
  max-width: 100%;
}

.srt-container .row .input-size-xxl {
  flex-grow: 0 !important;
  width: 35rem;
  max-width: 100%;
}

.srt-container .show-grid.row > div:nth-child(2) > .row-break {
  flex-basis: 100%;
  height: 0 !important;
  margin: 0 !important;
  width: 0 !important;
}

.srt-container .sectra-header > * {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  margin-left: -1px;
  line-height: 1.3;
}

.srt-container > .sectra-header:first-of-type > * {
  margin-top: 0;
}

.srt-container .sectra-header > h2 {
  margin-top: 2rem;
  margin-bottom: 0.5rem;
}

.srt-container .sectra-header > h3 {
  margin-top: 1.2rem;
  margin-bottom: 0.5rem;
}

.srt-container .sectra-header > label {
  margin-bottom: 4px;
}

.srt-container .row > div:nth-child(2) > .sectra-header {
  margin-bottom: 0;
}

.srt-container .row > div:nth-child(2) > .sectra-header > * {
  margin-top: .2rem;
  margin-left: 2px;
}

.srt-container .sectra-break {
  margin-top: 0.1rem;
  margin-bottom: 0.5rem;
  padding: 0 .4rem 0;
}

.srt-container .row .sectra-break {
   padding: 0;
}

.srt-container .sectra-break hr {
  width: 100%;
  margin: 0;
}

.srt-container .sectra-break > hr {
  border-top: 1px solid $spx-color-silver-750;
}
.theme-dark .srt-container .sectra-break > hr {
  border-top: 1px solid $spx-color-gray-500;
}

.srt-container .sectra-static-text {
  margin-top: 2px;
}

.srt-container .row > div:nth-child(2) > .sectra-static-text {
  margin-left: .15rem;
  flex-grow: 0;
}

.srt-container .sectra-static-text > .inline-text {
  margin: 0;
  white-space: normal;
}

.srt-container .sectra-checkbox .row > div {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.srt-container .row > div .sectra-checkbox .btn-checkbox,
.srt-container .row > div .sectra-checkbox .btn-radio {
  white-space: nowrap;
}

.srt-container .row div:nth-child(2) > div.sectra-checkbox {
  flex-grow: 0;
}

.srt-container .row div:nth-child(2) > div.sectra-dropdown:not(.fill) {
  flex-grow: 0;
}

.srt-container .row div:nth-child(2) > div.sectra-dropdown > select {
  max-width: 100%;
  min-width: 1.5rem;
}

.srt-container .row div:nth-child(2) > div.sectra-dropdown.fill,
.srt-container .row div:nth-child(2) > div.sectra-dropdown.fill > select {
  width: 100%;
}

.srt-container .row > div:nth-child(1) label {
  font-weight: normal;
  margin-bottom: 1rem;
  font-size: 10pt;
  padding-top: 2px;
  white-space: pre-wrap;
}

.srt-container .row.fullwidth > div:nth-child(1) label {
  margin-bottom: .5rem;
}

.theme-light .srt-container .row > div:nth-child(1) {
  color: rgba(#000, .8);
}

.theme-dark .srt-container .row > div:nth-child(1) {
  color: rgba(#fff, .8);
}

.srt-container .inline-text {
  font-size: 10pt;
  padding-top: 4px;
  opacity: .75;
  white-space: pre;
  font-size: 90%;
}

.srt-container .inline-text.label {
  display: inherit;
  padding: 2px 0 0;
  font-size: 95%;
  opacity: 1.0;
  color: black;
  font-weight: normal;
  line-height: inherit;
  color: inherit;
  text-align: inherit;
  white-space: inherit;
  vertical-align: inherit;
  border-radius: inherit;
}

.theme-dark .srt-container .inline-text.label {
  color: #bdbdbd;
}

.srt-container input[type='text'],
.srt-container input[type='number'],
.srt-container input[type='date'] {
  height: 24px;
  padding: 2px 5px;
  font-size: 12px;
  line-height: 1.5; /* If Placeholder of the input is moved up, rem/modify this. */
  -ms-border-radius: 3px;
  border-radius: 3px;
}

.srt-container div[class^="input-size"] > select {
  width: inherit;
}

.srt-container select, .srt-container div.input-size-fill > select {
  width: auto;
}

.btn:active, .btn:focus, .btn.active, .btn.focus, .btn.active.focus {
  outline: none !important;
}

.btn.btn-click {
  padding-bottom: 6px;
  font: normal normal 600 1em /1 'open_sans', 'Segoe UI', 'Trebuchet MS', Helvetica, Arial, sans-serif;
}

.btn.btn-link:hover {
  opacity: .85;
  font-weight: bold;
}

.theme-light .btn.btn-click:active  {
  border: 1px solid #d1d3d5 !important;
  padding-bottom: 6px !important;
}

.theme-dark .btn.btn-click:active {
  border: 1px solid rgba(255, 255, 255, 0.3) !important;
  padding-bottom: 6px !important;
}

.theme-light .btn-secondary.active.disabled {
  padding-bottom: 1px;
  border-bottom: 3px solid #679de4;
}

.theme-dark .btn-secondary.active.disabled {
  border-bottom: 3px solid #679de4;
  color: rgba(255, 255, 255, 0.7);
  padding-bottom: 1px;
}

.sectra-component-list {
  margin: 0 auto 1rem;
  padding: 0;
}

.sectra-component-list li {
  list-style: none;
  margin: 0 0.3rem 1.2rem;
  padding: .5rem 0 1.0rem 0;
  background-color: $spx-color-silver-550;
  border-radius: 5px;
  border: 1px solid $spx-color-silver-600;
}

.row .sectra-component-list li {
  margin-left: 0;
}

.theme-dark .sectra-component-list li {
  background-color: $spx-color-asphalt-325;
  border: 1px solid #253a5c;
}

.theme-dark .sectra-component-list li .info-text {
  color: #a9a9a9;
}

.sectra-component-list li.summary {
  padding-bottom: .1rem;
}

.sectra-component-list .list-summary {
  margin-top: 1px;
  line-height: 150%;
}

.sectra-component-list .list-summary .info-text {
  margin: 0;
  font-size: 100%;
}

.sectra-component-list .open .list-summary  {
  display: none;
}

.srt-container .list-header .row > div:nth-child(2) > * {
  margin-bottom: 0;
}

.srt-container .open .list-header .row > div:nth-child(2) > * {
  margin-bottom: 0.6rem;
}

.sectra-component-list li .list-header {
  padding-bottom: 7px;
}

.sectra-component-list li .list-header .row {
  margin-top: 0;
}

.sectra-component-list li .list-header .row > div:first-child {
  padding-left: 10px;
}

.sectra-component-list li .list-header .row > div:last-child {
  padding-right: 10px;
}

.sectra-component-list li .list-header .row > div:last-child > span > span {
  display: inline-block;
  opacity: .75;
  white-space: pre;
}

.sectra-component-list li.open .list-header .row > div:last-child  {
  display: flex;
  justify-content: flex-end;
}

.sectra-component-list li .list-header button {
  opacity: .75;
  color: black !important;
}

.sectra-component-list li .list-header button .svg-icon.list-collapse {
  margin-left: 1px;
}

.sectra-component-list li .list-header button > * {
  vertical-align: middle;
}

.theme-dark .sectra-component-list li .list-header button {
  color: white !important;
}

.sectra-component-list li .list-header button.delete {
  margin-top: -2px;
  margin-right: 6px;
}

.sectra-component-list .list-components {
  margin-bottom: -1rem;
}

.sectra-component-list li.summary > .list-components {
  height: 0;
  visibility: hidden;
  overflow: hidden;
  margin: 0;
}

.sectra-component-list .list-components > .container {
  padding-right: 12px;
}

.sectra-component-list-add {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1.7rem;
  margin-top: -1.3rem;
}

.sectra-component-list-add.empty {
  margin-top: 0;
}

.info-text {
  color: black;
  opacity: .65;
}

.theme-dark .info-text {
  color: $spx-color-silver-750;
}

.info-text.small {
  font-size: 94%;
}

.info-text.italic {
  font-style: italic;
}

.svg-icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
  vertical-align: middle;
}

.svg-icon.delete {
  font-size: 150%;
  text-align: center;
  display: inline-block;
  color: $spx-color-red-600;
  border-radius: 50%;
  :where(.theme-light) & {
    border: 1px solid $spx-color-silver-550;
  }
  :where(.theme-dark) & {
    border: 1px solid $spx-color-asphalt-325;
    color: $spx-color-red-400
  }
}

.svg-icon.no-border {
  border: none;
}

.svg-icon.undo {
  font-size: 150%;
  fill: black;
  border: 1px solid transparent;
  border-radius: 50%;
}

.svg-icon.svgcheckbox {
  fill: black;
}

.theme-dark .svg-icon.svgcheckbox {
  fill: white;
}

.theme-dark .svg-icon.undo {
  fill: white;
}

.svg-icon.version {
  font-size: 120%;
}

.theme-dark .svg-icon.version {
  color: white;
}

.theme-light .svg-icon.version {
  color: black;
}

.svg-icon.question {
  font-size: 150%;
}

.theme-dark .svg-icon.question {
  color: rgba(255,255,255,.7);
}

.theme-light .svg-icon.question {
  color: rgba(0,0,0,.7);
}

button:not(:disabled):focus .svg-icon.delete, button:not(:disabled) .svg-icon.delete:hover  {
  background-color: #eef3fb;
  border: 1px solid #c4dbff;
}

.theme-dark button:not(:disabled):focus .svg-icon.delete, .theme-dark button:not(:disabled) .svg-icon.delete:hover  {
  background-color: rgba(150, 189, 241, .2);
  border: 1px solid rgba(150, 189, 241, .2);
}

:where(.theme-dark .svg-icon.delete) {
}

button:not(:disabled):focus .svg-icon.undo, button:not(:disabled) .svg-icon.undo:hover  {
  background-color: #eef3fb;
  border: 1px solid #c4dbff;
}

.theme-dark button:not(:disabled):focus .svg-icon.undo, .theme-dark button:not(:disabled) .svg-icon.undo:hover  {
  background-color: rgba(150, 189, 241, .2);
  border: 1px solid rgba(150, 189, 241, .2);
}

.container.sectra-space .vspace.xs {
  height: .5rem;
}
.container.sectra-space .vspace.s {
  height: 1rem;
}
.container.sectra-space .vspace.m {
  height: 1.2rem;
}
.container.sectra-space .vspace.l {
  height: 2rem;
}
.container.sectra-space .vspace.xl {
  height: 3rem;
}

.container.painter {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.container.painter.left {
  justify-content: flex-start;
}

.container.painter.center {
  justify-content: center;
}

.srt-container .row div:nth-child(2) > .painter {
  flex-grow: 0;
  display: flex;
}

.painter .canvas-toolbox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.painter .canvas-toolbox > .canvas-toolbox-buttons {
  display: flex;
  flex-direction: column;
}

.painter .canvas-toolbox > .canvas-toolbox-buttons > label  {
  cursor: pointer;
}

.painter .canvas-toolbox > .canvas-toolbox-buttons > label > .toolbox-icon {
  display: inline-block;
  min-width: 24px;
  min-height: 24px;
  text-align: center;
}

.painter .canvas-toolbox > .canvas-toolbox-buttons > label > .toolbox-icon > img {
  width: 24px;
  height: 24px;
}

.painter .canvas-toolbox > .canvas-toolbox-buttons > label > span {
  display: inline-block;
  margin-left: 3px;
  line-height: 24px;
  vertical-align: middle;
}

.srt-container .textarea {
  height: auto;
  padding: 2px 5px;
  font-size: 12px;
  line-height: 1.5;
  overflow: auto;
}

.srt-container .row.sectra-textarea {
  margin-bottom: 1.2rem;
}

.srt-container .textarea[placeholder]:empty:before {
  content: attr(placeholder);
  color: #999;
  cursor: text;
}

.theme-dark .srt-container .textarea {
  color: #eaecee;
  border: none;
  -ms-border-radius: 3px;
  border-radius: 3px;
  border: 1px solid $spx-color-asphalt-400;
  background-color: #263854;
}

.theme-light .srt-container .textarea {
  color: #191919;
  border-radius: 3px;
  border: 1px solid $spx-color-silver-700;
  background-color: #fff;
}

.theme-dark .srt-container .textarea.disabled,
.theme-dark .srt-container .textarea[disabled] {
  color: #888e98;
  border: 1px solid #263041;
  background-color: #132034;
}

.theme-light .srt-container .textarea.disabled,
.theme-light .srt-container .textarea[disabled] {
  color: #7f7f7f;
  border: 1px solid #c6c7ca;
  background-color: #ebedf0;
}

input[type='date']::-webkit-clear-button,
input[type='date']::-webkit-inner-spin-button {
  display: none;
}

input[type='date']::-webkit-calendar-picker-indicator {
  cursor: pointer;
  margin: 2px -3px 0 0;
}

input[type='date']::-webkit-calendar-picker-indicator:hover {
  background-color: transparent;
}

.theme-dark input[type='date']::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.sectra-checkboxlist .checkboxlistcolumns-2 > div {
  width: 50%;
}
.sectra-checkboxlist .checkboxlistcolumns-3 > div {
  width: 33%;
}
.sectra-checkboxlist .checkboxlistcolumns-4 > div {
  width: 25%;
}

.sectra-checkboxlist .checklist-other {
  margin-top: .15rem;
}

.input-number-container {
  width: 100%;
  position: relative;
}

.input-number-container > .spinner-container {
  position: absolute;
  right: 0;
  width: 15px;
  user-select: none;
  margin: 1px 1px 1px 0;
}

.input-number-container > input[type='text'] {
  padding-right: 20px;
}

.input-number-container > .spinner-container > div {
  display: none;
  cursor: pointer;
  background-color: rgba(#000, .05);
}

.input-number-container:hover > .spinner-container > div, 
.input-number-container.focused > .spinner-container > div {
  display: block;
}

.spinner-container > .input-spinner-up {
  padding: 2px 3px 0 3px;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}

.spinner-container > .input-spinner-down {
  padding: 0 3px 2px 3px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}
.input-number-container >.spinner-container.active-up > .input-spinner-up {
  background-color: rgba(#000, .15);
}
.input-number-container > .spinner-container.active-down > .input-spinner-down {
  background-color: rgba(#000, .15);
}

.spinner-container svg {
  width: 9px;
  height: 9px;
  display: block;
}

.spinner-container > .input-spinner-up > svg,
.spinner-container > .input-spinner-down > svg {
  fill:rgba(0, 0, 0, 0.6);
}
.spinner-container > .input-spinner-up:hover > svg,
.spinner-container > .input-spinner-down:hover > svg {
  fill:rgba(0, 0, 0, 0.9);
}

.theme-dark .input-number-container > .spinner-container > div {
  background-color: rgba(#fff, .05);
}

.theme-dark .spinner-container.active-up > .input-spinner-up {
  background-color: rgba(#fff, .15);
}
.theme-dark .spinner-container.active-down > .input-spinner-down {
  background-color: rgba(#fff, .15);
}

.theme-dark .spinner-container > .input-spinner-up > svg,
.theme-dark .spinner-container > .input-spinner-down > svg {
  fill:rgba(255, 255, 255, 0.35);
}
.theme-dark .spinner-container > .input-spinner-up:hover > svg,
.theme-dark .spinner-container > .input-spinner-down:hover > svg {
  fill:rgba(255, 255, 255, 0.65);
}

.srt-container .component-warning .form-control {
  box-shadow: 0 0 2px 2px $spx-color-yellow;
  transition-delay: 100ms;
}
.srt-container .component-error .form-control {
  box-shadow: 0 0 2px 2px $spx-color-red;
  transition-delay: 100ms;
}

.srt-container .sectra-mandatory-fields {
  margin: 0 1rem 1.2rem 1rem;
  border-top: 1px solid $spx-color-silver-750;
  padding: .4rem .1rem 0;
}
.theme-dark .srt-container .sectra-mandatory-fields  {
  border-color: $spx-color-gray;
}
.srt-container .sectra-mandatory-fields > div {
  margin: 0 0 .4rem;
}
.srt-container .sectra-mandatory-fields > div span {
  opacity: .9;
}
.theme-dark .srt-container .sectra-mandatory-fields > div span {
  opacity: .7;
}
.srt-container .sectra-mandatory-fields ul, 
.srt-container .sectra-mandatory-fields li {
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline;
}

.srt-container .sectra-mandatory-fields .fields-prohibit li span,
.srt-container .sectra-mandatory-fields .fields-prohibit a {
  color: $spx-color-red-700;
}
.theme-dark .srt-container .sectra-mandatory-fields .fields-prohibit li span,
.theme-dark .srt-container .sectra-mandatory-fields .fields-prohibit a {
  color: $spx-color-red-300;
}
.srt-container .sectra-mandatory-fields .fields-alert li span,
.srt-container .sectra-mandatory-fields .fields-alert a {
  color: $spx-color-yellow-700;
}
.theme-dark .srt-container .sectra-mandatory-fields .fields-alert li span,
.theme-dark .srt-container .sectra-mandatory-fields .fields-alert a {
  color: $spx-color-yellow-300;
}

.srt-container .sectra-button .btn.btn-default {
  font: normal normal 600 1em /1 'open_sans', 'Segoe UI', 'Trebuchet MS', Helvetica, Arial, sans-serif;
  border-width: 2px !important;
}

.srt-container .sectra-button .btn.btn-default:not([disabled]):not(.disabled):active {
  padding-bottom: 6px;
}

.srt-container .sectra-button .btn.btn-default.component-padding:not([disabled]):not(.disabled):active {
  padding-bottom: 4px;
}

.srt-container .sectra-button .btn.btn-default.component-padding {
  padding: 3px 9px 4px;
}

.srt-container .row div:nth-child(2) > .sectra-button:not(.container) {
  flex-grow: 0;
}

.srt-container .sectra-button.container {
  margin-bottom: .4rem;
  display: flex;
  justify-content: flex-end;
  position: relative;
}
.srt-container .sectra-button.container.absolute {
  position: relative;
  margin-bottom: 1rem;
}

.srt-container .sectra-button.container.align-left {
  justify-content: flex-start;
}
.srt-container .sectra-button.container.align-center {
  justify-content: center;
}

.srt-container .sectra-button .btn.btn-primary:focus {
  border-color: #265ba0;
}
.theme-dark .srt-container .sectra-button .btn.btn-primary:focus {
  border-color: rgba(169, 202, 246, .7);
}
.srt-container .btn.btn-primary:focus:active,
.theme-dark .srt-container .sectra-button .btn.btn-primary:focus:active {
  border-color: #3c73bb;
}

.srt-container .sectra-button .btn.btn-warning:focus {
  border-color: #d58512;
}

.srt-container .sectra-button .btn.btn-gray {
  color: rgba(0, 0, 0, .9);
  background-color: rgba(29, 43, 67, .2);
}
.srt-container .sectra-button .btn.btn-gray:not([disabled]):not(.disabled):focus {
  background-color: rgba(29, 43, 67, .2);
  border-color: rgba(29, 43, 67, .2);
}
.srt-container .sectra-button .btn.btn-gray:not([disabled]):not(.disabled):hover {
  background-color: rgba(29, 43, 67, .3);
  border-color: rgba(0, 0, 0, .0);
}

.theme-dark .srt-container .sectra-button .btn.btn-gray {
  color: rgba(255, 255, 255, .9);
  background-color: rgba(255, 255, 255, .3);
  border-color: rgba(0, 0, 0, .0);
}
.theme-dark .srt-container .sectra-button .btn.btn-gray:not([disabled]):not(.disabled):focus {
  background-color: rgba(255, 255, 255, .3);
  border-color: rgba(255, 255, 255, .3);
}
.theme-dark .srt-container .sectra-button .btn.btn-gray:not([disabled]):not(.disabled):hover {
  background-color: rgba(255, 255, 255, .5);
  border-color: rgba(0, 0, 0, .0);
}
.theme-dark .srt-container .sectra-button .btn.btn-gray:not([disabled]):not(.disabled):active {
  background-color: rgba(255, 255, 255, .6);
}
.theme-dark .srt-container .sectra-button .btn.btn-gray[disabled] {
  color: rgba(255, 255, 255, .3);
  background-color: rgba(255, 255, 255, .15);
}

.form-control.speech-focused {
  border-color: rgb(102, 175, 233);
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.srt-container .sectra-options.speech-focused > .btn-group {
  border-radius: 3px;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .8), 0 0 6px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .8), 0 0 6px rgba(102, 175, 233, 0.6);
}
.btn-checkbox.speech-focused:not(.disabled) > div, 
.btn-checkbox.speech-focused:not(.disabled):hover > div,
.btn-radio.speech-focused:not(.disabled) > div, 
.btn-radio.speech-focused:not(.disabled):hover > div {
  background-color: rgba(150, 189, 241, 0.2);
  border-color: rgba(169, 202, 246, 0.3);
}

.outputTable td {
  border: 1px solid;
  padding: 5px;
}