@import '../../BootstrapSectra/Resources/spx-colors';
@import '../../BootstrapSectra/Resources/mixins';

.Treeview {
    ul, li {
        margin: 0;
        padding: 0;
        list-style: none;
        font-size: 14px;
    }

    ul {
        font-size: 1.05rem;

        &.collapsed {
            display: none;
        }

        > li {                
            > ul {
                padding-left: .9rem;
            }

            > span {
                display: block;
                @include no-select();
                padding-left: .4rem;
                border: 1px solid transparent;

                &.active {
                    background-color: $spx-color-silver-575;
                    > span:first-child > svg {
                        fill: rgba(0, 0, 0, .7);
                    }
                }

                &:not(.active):hover {
                    background-color: $spx-color-silver-550;
                }

                &:not(:hover).drag-hover {
                    border: 1px dashed $spx-color-silver-750;
                }

                > span {
                    padding: .2rem 0;    
                    display: inline-block;
                }

                > span:first-child {
                    padding: 0 .3rem 0 .2rem;
                    vertical-align: middle;
                    cursor: pointer;

                    > svg {
                        display: inline;
                        height: .9em;    
                        width: 0.9em;
                        fill: rgba(0, 0, 0, .5);
                    }
                }
            }

            &.empty > span {
                font-style: italic;
            }
            &.type-folder > * > span {
                padding-left: .1rem;
            }
        }

        > li.locked > span > span:first-child {
            cursor: default;
            > svg {
                fill: rgba(0, 0, 0, .7);
            }
        }
        
    }

    // Handle top level
    > ul > li > span {
        padding-left: 0;
    }
}

.tree-disabled {
    opacity: .4;
}

.theme-dark .Treeview {
    ul {
        > li {
            > span {
                &.active {
                    background-color: $spx-color-asphalt-500;
                    > span:first-child > svg {
                        fill: rgba(255, 255, 255, .7);
                    }
                }
        
                &:not(.active):hover {
                    background-color: $spx-color-asphalt-450;
                }
                &:not(:hover).drag-hover {
                    border-color: $spx-color-gray;
                }
    
                > span:first-child  > svg {
                    fill: rgba(255, 255, 255, .5);
                }
            }
        }
        > li.locked > span > span:first-child > svg {
            fill: rgba(255, 255, 255, .7);
        }
    }
}