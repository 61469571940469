
.SectraGraph .js-plotly-plot,.SectraGraph .js-plotly-plot div {
	direction:ltr;font-family:'Open Sans', verdana, arial, sans-serif;margin:0;padding:0;
}
.SectraGraph .js-plotly-plot input,.SectraGraph .js-plotly-plot button {
	font-family:'Open Sans', verdana, arial, sans-serif;
}
.SectraGraph .js-plotly-plot input:focus,.SectraGraph .js-plotly-plot button:focus {
	outline:none;
}
.SectraGraph .js-plotly-plot a {
	text-decoration:none;
}
.SectraGraph .js-plotly-plot a:hover {
	text-decoration:none;
}
.SectraGraph .js-plotly-plot .crisp {
  shape-rendering:crispEdges;
}
.SectraGraph .js-plotly-plot .user-select-none {
  -webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;-o-user-select:none;user-select:none;
}
.SectraGraph .js-plotly-plot svg {
  overflow:hidden;
}
.SectraGraph .js-plotly-plot svg a {
  fill:#447adb;
}
.SectraGraph .js-plotly-plot svg a:hover {
  fill:#3c6dc5;
}
.SectraGraph .js-plotly-plot .main-svg {
  position:absolute;top:0;left:0;pointer-events:none;
}
.SectraGraph .js-plotly-plot .main-svg .draglayer {
  pointer-events:all;
}
.SectraGraph .js-plotly-plot .cursor-default {
	cursor:default;
}
.SectraGraph .js-plotly-plot .cursor-pointer {
	cursor:pointer;
}
.SectraGraph .js-plotly-plot .cursor-crosshair {
	cursor:crosshair;
}
.SectraGraph .js-plotly-plot .cursor-move {
	cursor:move;
}
.SectraGraph .js-plotly-plot .cursor-col-resize {
	cursor:col-resize;
}
.SectraGraph .js-plotly-plot .cursor-row-resize {
	cursor:row-resize;
}
.SectraGraph .js-plotly-plot .cursor-ns-resize {
	cursor:ns-resize;
}
.SectraGraph .js-plotly-plot .cursor-ew-resize {
	cursor:ew-resize;
}
.SectraGraph .js-plotly-plot .cursor-sw-resize {
	cursor:sw-resize;
}
.SectraGraph .js-plotly-plot .cursor-s-resize {
	cursor:s-resize;
}
.SectraGraph .js-plotly-plot .cursor-se-resize {
	cursor:se-resize;
}
.SectraGraph .js-plotly-plot .cursor-w-resize {
	cursor:w-resize;
}
.SectraGraph .js-plotly-plot .cursor-e-resize {
	cursor:e-resize;
}
.SectraGraph .js-plotly-plot .cursor-nw-resize {
	cursor:nw-resize;
}
.SectraGraph .js-plotly-plot .cursor-n-resize {
	cursor:n-resize;
}
.SectraGraph .js-plotly-plot .cursor-ne-resize {
	cursor:ne-resize;
}
.SectraGraph .js-plotly-plot .cursor-grab {
	cursor:-webkit-grab;cursor:grab;
}
.SectraGraph .js-plotly-plot .modebar {
	position:absolute;top:2px;right:2px;
}
.SectraGraph .js-plotly-plot .ease-bg {
	-webkit-transition:background-color 0.3s ease 0s;-moz-transition:background-color 0.3s ease 0s;-ms-transition:background-color 0.3s ease 0s;-o-transition:background-color 0.3s ease 0s;transition:background-color 0.3s ease 0s;
}
.SectraGraph .js-plotly-plot .modebar--hover>:not(.watermark) {
	opacity:0;-webkit-transition:opacity 0.3s ease 0s;-moz-transition:opacity 0.3s ease 0s;-ms-transition:opacity 0.3s ease 0s;-o-transition:opacity 0.3s ease 0s;transition:opacity 0.3s ease 0s;
}
.SectraGraph .js-plotly-plot:hover .modebar--hover .modebar-group {
	opacity:1;
}
.SectraGraph .js-plotly-plot .modebar-group {
	float:left;display:inline-block;box-sizing:border-box;padding-left:8px;position:relative;vertical-align:middle;white-space:nowrap;
}
.SectraGraph .js-plotly-plot .modebar-btn {
	position:relative;font-size:16px;padding:3px 4px;height:22px;cursor:pointer;line-height:normal;box-sizing:border-box;
}
.SectraGraph .js-plotly-plot .modebar-btn svg {
	position:relative;top:2px;
}
.SectraGraph .js-plotly-plot .modebar.vertical {
	display:flex;flex-direction:column;flex-wrap:wrap;align-content:flex-end;max-height:100%;
}
.SectraGraph .js-plotly-plot .modebar.vertical svg {
	top:-1px;
}
.SectraGraph .js-plotly-plot .modebar.vertical .modebar-group {
	display:block;float:none;padding-left:0px;padding-bottom:8px;
}
.SectraGraph .js-plotly-plot .modebar.vertical .modebar-group .modebar-btn {
	display:block;text-align:center;
}
.SectraGraph .js-plotly-plot [data-title]:before,.SectraGraph .js-plotly-plot [data-title]:after {
	position:absolute;-webkit-transform:translate3d(0, 0, 0);-moz-transform:translate3d(0, 0, 0);-ms-transform:translate3d(0, 0, 0);-o-transform:translate3d(0, 0, 0);transform:translate3d(0, 0, 0);display:none;opacity:0;z-index:1001;pointer-events:none;top:110%;right:50%;
}
.SectraGraph .js-plotly-plot [data-title]:hover:before,.SectraGraph .js-plotly-plot [data-title]:hover:after {
	display:block;opacity:1;
}
.SectraGraph .js-plotly-plot [data-title]:before {
	content:'';position:absolute;background:transparent;border:6px solid transparent;z-index:1002;margin-top:-12px;border-bottom-color:#69738a;margin-right:-6px;
}
.SectraGraph .js-plotly-plot [data-title]:after {
	content:attr(data-title);background:#69738a;color:white;padding:8px 10px;font-size:12px;line-height:12px;white-space:nowrap;margin-right:-18px;border-radius:2px;
}
.SectraGraph .js-plotly-plot .vertical [data-title]:before,.SectraGraph .js-plotly-plot .vertical [data-title]:after {
	top:0%;right:200%;
}
.SectraGraph .js-plotly-plot .vertical [data-title]:before {
	border:6px solid transparent;border-left-color:#69738a;margin-top:8px;margin-right:-30px;
}
.SectraGraph .js-plotly-plot .select-outline {
	fill:none;stroke-width:1;shape-rendering:crispEdges;
}
.SectraGraph .js-plotly-plot .select-outline-1 {
	stroke:white;
}
.SectraGraph .js-plotly-plot .select-outline-2 {
	stroke:black;stroke-dasharray:2px 2px;
}