﻿@import '../Resources/_spx-colors';

.navbar-default {
    background-image: none;
    background-color: $spx-color-asphalt;
    border-color: $spx-color-asphalt;
}

.navbar-nav li.navbar-signin-out a:hover {
  color: #fff;
}

.navbar-nav li.navbar-signin-out a {
  padding: inherit;
  line-height: inherit;
  font-size: inherit;
}

.navbar-default .navbar-nav > li:not(.navbar-signin-out) > a:hover,
.navbar-default .navbar-nav > li:not(.navbar-signin-out) > a:focus {
    background-image: none;
    background-color: $spx-color-asphalt-250;
}

.navbar-default .navbar-nav {
    & > .active > a,
    & > .open > a {
        &, &:hover, &:focus {
            color: $spx-color-white;
            background-color: $spx-color-asphalt-250;
        }
    }
}

.navbar-default .navbar-nav > li > a {
    &, &:focus {
        height: 54px;
        color: $spx-color-white;
    }
}

.navbar-default .navbar-link {
    color: $spx-color-white;
}

.navbar-default .navbar-link:hover {
    color: $spx-color-white;
}

.navbar-default .navbar-text {
    color: $spx-color-white;
}

.navbar-default .navbar-brand {
    height: 54px;
    color: $spx-color-white;
}

.navbar-default .navbar-nav > li:not(.navbar-signin-out) > a:hover {
    color: $spx-color-white;
    background-color: $spx-color-asphalt-250;
}

.navbar-default a.navbar-brand:hover,
.navbar-default a.navbar-brand:focus {
    color: $spx-color-white;
    background-image: none;
    background-color: $spx-color-asphalt-250;
}

.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .active > a {
    background-image: none;
    background-color: $spx-color-asphalt-250;
}

.navbar a {
    font-size: 16px;
}

.lead {
    color: $spx-color-black;
}

.navbar-default span.navbar-brand:hover {
    color: $spx-color-white;
    cursor: default;
}

.sectra-logo {
    height: 24px;
    width: 132px;
    color: $spx-color-white;
    fill: $spx-color-white;
    margin-right: 5px;
    padding: 0;
}

#layout-footer {
    position: fixed;
    bottom: 0;
    z-index: 1030;
    width: 100%;
    text-align: right;
    background-color: $spx-color-silver-550;

    &, & a {
        color: $spx-color-gray;
    }
}