@import '../Resources/_spx-colors';

$disabled-gray: rgba(128, 128, 128, 0.5);

div.in-progress {
    position: relative;

    &::before {
        background-color: $disabled-gray;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 200;
        content: "";
    }

    &::after {
        width: 5rem;
        height: 5rem;
        border: 0.625rem solid;
        border-radius: 50%;
        border-color: $spx-color-white rgba($spx-color-white, 0.2) rgba($spx-color-white, 0.2) rgba($spx-color-white, 0.2);
        display: inline-block;
        left: 50%;
        top: 50%;
        position: absolute;
        z-index: 200;
        margin: -2.5rem 0 0 -2.5rem;
        content: "";
        @extend %progress-animation;
    }
}

.btn.in-progress::after {
    width: 2.5rem;
    height: 2.5rem;
    border: 0.25rem solid;
    border-color: $spx-color-white rgba($spx-color-black, 0.2) rgba($spx-color-black, 0.2) rgba($spx-color-black, 0.2);
    margin: -0.5rem 0 0 -3rem;
    @extend %progress-animation;
}

%progress-animation {
    border-radius: 50%;    
    display: inline-block;
    position: absolute;
    z-index: 200;
    content: "";
    animation-name: progress-rotation;
    animation-duration: 0.6s;
    animation-timing-function: linear;
    animation-delay: 0;
    animation-iteration-count: infinite;
    animation-direction: normal;
    animation-fill-mode: none;
    animation-play-state: running;
}

@keyframes progress-rotation {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(359deg);
    }
}
