@import './BootstrapSectra/Resources/spx-colors';

.theme-light .required-mark {
    color: $spx-color-red-600;
}

.theme-dark .required-mark {
    color: $spx-color-red-500;
}

.help-page > table {
    margin-bottom: 3rem;
}

.help-page > table tr {
    vertical-align: top;
}

.help-page > table tr td {
    white-space: pre-line;
    padding: .1rem .2rem .5rem 0;
}

.help-page > table tr td:first-child {
    width: 200px;
    word-break: break-all;
}

.help-page .property-type {
    font-size: 8pt;
}

.help-page .property-type, 
.help-page .allowed-values > span > span  {
    color: $spx-color-gray;
}

.help-page .property-type > span {
    color: black;
}

.help-page .allowed-values {
    font-size: 9pt;
    margin: 1rem 0;
}

.help-page .array-type {
    margin: 1rem 0;
}

.help-page .array-type > span {
    font-size: 9pt;
}

.help-page .allowed-values > span {
    font-size: 10pt;
    display: block;
    margin-left: .5rem;
}

.help-page ul {
    padding-left: 1.7rem;
}

.help-page ul > li > div {
    margin-left:-0.2em;
}

.theme-dark .help-page .property-type, 
.theme-dark .help-page .allowed-values > span > span {
    color: $spx-color-silver-750;
}
.theme-dark .help-page .property-type > span  {
    color: white;
}