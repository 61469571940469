@import '../BootstrapSectra/Resources/spx-colors';

.sectra-forms-navbar {
    font-size: 1.25rem;
    color: $spx-color-white;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;

    & li,
    & ul {
        all: unset;
        /* required for hover effect */
        position: relative;
        isolation: isolate;
    }
    
    /* Hover over or has item inside that is hoverd over */
    & li:not(:has(>:disabled)):is(:hover, :focus-visible, :has(*:focus-visible))::before {
        content: "";
        position: absolute;
        inset: 1px;
        background-color: $spx-color-asphalt-350;
        z-index: -1;
    }

    & button {
        all: unset;
        height: 100%;
        width: 100%;
        box-sizing: border-box;

        &.clickable {
            cursor: pointer;
        }

        &:disabled {
            opacity: .5;
            cursor: unset;
        }
    }

    /* Icons in dropdowns */
    & .dropdown svg {
        max-width: 1.25em;
        max-height: 1.25em;
    }

    /* Item in nav */
    & li>:is(a, span, button, svg) {
        font-size: inherit;
        color: inherit;
        padding-inline: 1rem;

        display: grid;
        height: 100%;
        align-items: center;
        text-decoration: none;
    }

    /* highlight if it is active or has an active child (only highlight first child)*/
    & li:has(a.active)>*:first-child {
        color: $spx-color-orange-400;
    }

    &>ul {
        display: flex;
        flex-direction: row;
        height: 100%;

        &>li {
            display: block;
            position: relative;
            height: 100%;

            &:not(:hover, :focus-visible, :has(*:focus-visible))>ul {
                /* clip-path instead of visibility or display: none 
                   to make it possible to navigate to with keyboard */
                clip-path: inset(50%);
            }
        }
    }

    /* dropdown for nav items */
    & .dropdown {
        position: absolute;
        top: 100%;
        left: 0;
        min-width: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        background: $spx-color-asphalt-525;
        border: 1px solid $spx-color-asphalt-350;
        text-wrap: nowrap;

        /* Let dropdown grow towards the left instead of right */
        &.left {
            left: unset;
            right: 0;
        }

        &>li>* {
            padding-inline: 1rem;
            line-height: 2;
        }

        /* Align items if one has an icon */
        &:has(svg)>li>* {
            grid-template-columns: 2em 1fr;
            align-items: center;
        }
    }

    /* SECTRA brand logo */
    & .brand {
        display: grid;
        grid-template-columns: max-content max-content;
        align-items: baseline;
        font-weight: bold;
        & div {
            margin-right: -4px;
        }
    }

    & .profile {
        &>span>svg {
            max-width: 2.5rem;
            max-height: 2.5rem;
        }

        & .name {
            &::before {
                display: none;
            }
    
            color: $spx-color-gray;
            margin-bottom: 0.5rem;
            text-transform: capitalize;
        }
    }
}