$defaultFonts: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

body {
  margin: 0;
  font-family: $defaultFonts;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  isolation: isolate;
}

.popoverContent {
  font-family: $defaultFonts;
  font-size: 10pt;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  max-width: 100%;
}

#logo-link:hover {
  background-color: transparent;
}

#logo-text {
  cursor: pointer;
}

@import './BootstrapSectra/Theme/navbar';
@import './BootstrapSectra/Theme/progress-indicator';
@import './TabStyling.scss';

.MainContent {
  display: flex;
  flex-direction: row;
  height: 100%;

  .nav > li > a {
    padding: 10px 15px 8px;
    user-select: none;
  }

  .table.table-list tbody .svg-icon.delete {
    border: none;
  }
}

.TemplateAdmin {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 60px);

    &.NotLatestVersion {
        height: calc(100vh - 92px);
        
        > .app-warning.alert {
            height: 28px;
            margin: 2px;
        }
    }
}

.theme-light .MainContent .nav > li > a[aria-selected='true'] {
  border-bottom: 1px solid $spx-color-silver-500;
}

.theme-dark .MainContent .nav > li > a[aria-selected='true'] {
  border-bottom: 1px solid $spx-color-asphalt-400;
}

.DropzoneArea {
  border: 2px solid white;
  border-radius: 10px;
  cursor: pointer;
}

.DropzoneText {
  width: 100%;
  padding-top: 200px;
  padding-bottom: 200px;
  text-align: center;
}

.templateListItem{
  color: black;
}

.templateListItem:hover {
  cursor: pointer;
}

.navbar {
  height: 60px;
  margin-bottom: 0;
  border: none;
  border-radius: 0;
}

.navbar > .container {
  width: 100% !important;
}

.navbar-default .navbar-brand, .navbar-default .navbar-brand:hover {
  font-size: 18px;
  line-height: 60px;
  padding: 0 15px;
  display: inline;
  color: $spx-color-white;
}

.navbar-nav {
  display: inline;
  color: #fff;
  font-size: 18px;
  line-height: 60px;
}

.navbar-default {
  height: 60px;
  user-select: none;
}

.navbar-brand > span {
  vertical-align: top;
  white-space: nowrap;
}
.navbar-brand > span:hover {
  cursor: default;
  color: $spx-color-white;
}

.logo-container {
  display: inline-flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}

.navbar-nav.disabled {
  opacity: .5;
}

.timeout-dialog {
  margin: 70px auto -60px;
}

.timeout-dialog .alert {
  padding: 10px;
}

.timeout-dialog .alert > .close {
  right: -5px;
  top: -10px;
}

.btn.btn-info {
  font: normal normal 600 1em /1 'open_sans', 'Segoe UI', 'Trebuchet MS', Helvetica, Arial, sans-serif;
}


.navbar-nav.dropdown > a {  
  color: #fff;
  font-size: 18px;
  line-height: 60px;
}

.theme-dark .navbar-nav.dropdown .dropdown-menu li:not(.disabled) a {
  color: #fff;
}

.theme-dark .navbar-nav.dropdown .dropdown-menu li:not(.disabled, .navbar-signin-out) a:hover {
  background-color: $spx-color-asphalt-350;
}

.theme-dark tr.selected {
  background-color: $spx-color-asphalt-300;
}

.right {
  text-align: right;
}

.table.table-list tbody {
    .svg-icon.delete {
        border: none;
    }

    tr {
        &:hover {
            background-color: $spx-color-silver-700;
        }

        td:nth-child(2) {
            max-width: 500px;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        td.actionButtons {
            text-align: right;
            white-space: nowrap;
            cursor: default;

            > button {
                padding: 0 0 0 6px;
                vertical-align: top;

                > svg.svgcheckbox {
                    vertical-align: baseline;      
                    font-size: 13px;          
                }
            }
        }
    }
}

.theme-dark .table.table-list tbody tr:hover {
  background-color: $spx-color-asphalt-425;
}

tr.deleted {
  text-decoration: line-through;
}

.no-btn {
  border: none;
  padding: 0;
}

.theme-dark .no-btn {
  background-color: $spx-color-asphalt-400;
}

.theme-light .no-btn {
  background-color: $spx-color-silver-500;
}