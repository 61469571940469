﻿@mixin box-shadow($value) {
    -webkit-box-shadow: $value;
    -moz-box-shadow: $value;
    box-shadow: $value;
}

@mixin border-radius($value) {
    border-radius: $value;
    -webkit-border-radius: $value;
    -moz-border-radius: $value;
}

@mixin no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}