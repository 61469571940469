@import '../../BootstrapSectra/Resources/spx-colors';

.SectraHtmlBox{
  padding: 10px;
  line-height: 1.4;
  white-space: pre-wrap;
  -ms-border-radius: 3px;
  border-radius: 3px;
  min-height: 40px;
  margin-bottom: 1.2rem;
}

.theme-dark .SectraHtmlBox {
  color: $spx-color-silver-550;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background-color: $spx-color-asphalt-350;
}

.theme-light .SectraHtmlBox {
  background-color: #fff;
  color: #191919;
  border: 1px solid $spx-color-silver-700;
}