﻿//
// The color palette used in SPX. Color scheme definitions refer to these ones.
//
$spx-color-black-500: #000;
$spx-color-black: #000;
$spx-color-white-500: #fff;
$spx-color-white: #fff;
$spx-color-gray-500: #808080;
$spx-color-gray: #808080;
$spx-color-asphalt-600: #000;
$spx-color-asphalt-575: #00040b;
$spx-color-asphalt-550: #010916;
$spx-color-asphalt-525: #030e1f;
$spx-color-asphalt-500: #071326;
$spx-color-asphalt: #071326;
$spx-color-asphalt-475: #0d192d;
$spx-color-asphalt-450: #131f34;
$spx-color-asphalt-425: #18253b;
$spx-color-asphalt-400: #1d2b43;
$spx-color-asphalt-375: #21314b;
$spx-color-asphalt-350: #253753;
$spx-color-asphalt-325: #293d5c;
$spx-color-asphalt-300: #2d4265;
$spx-color-asphalt-275: #30486e;
$spx-color-asphalt-250: #334e78;
$spx-color-silver-750: #babbbd;
$spx-color-silver-725: #c0c1c4;
$spx-color-silver-700: #c6c8ca;
$spx-color-silver-675: #ccced0;
$spx-color-silver-650: #d2d4d7;
$spx-color-silver-625: #d8dadd;
$spx-color-silver-600: #dfe0e3;
$spx-color-silver-575: #e5e7ea;
$spx-color-silver-550: #ebedf0;
$spx-color-silver-525: #f1f3f6;
$spx-color-silver-500: #f7f9fc;
$spx-color-silver: #f7f9fc;
$spx-color-silver-475: #fbfcfe;
$spx-color-silver-450: #fff;
$spx-color-blue-800: #092c5a;
$spx-color-blue-750: #0f386d;
$spx-color-blue-700: #15437f;
$spx-color-blue-650: #1d4f90;
$spx-color-blue-600: #265ba0;
$spx-color-blue-550: #3067ae;
$spx-color-blue-500: #3c73bb;
$spx-color-blue: #3c73bb;
$spx-color-blue-450: #487fc7;
$spx-color-blue-400: #568bd1;
$spx-color-blue-350: #6498db;
$spx-color-blue-300: #74a4e3;
$spx-color-blue-250: #84b1ea;
$spx-color-blue-200: #96bdf1;
$spx-color-blue-150: #a9caf6;
$spx-color-blue-100: #bdd7fa;
$spx-color-blue-50: #d2e4fc;
$spx-color-blue-0: #e8f2fe;
$spx-color-blue-A500: #679de4;
$spx-color-green-700: #2f893b;
$spx-color-green-600: #3fa64d;
$spx-color-green-500: #51c260;
$spx-color-green: #51c260;
$spx-color-green-400: #6fd37d;
$spx-color-green-300: #90e19b;
$spx-color-green-A500: #4dff64;
$spx-color-yellow-700: #b67a28;
$spx-color-yellow-600: #db9e30;
$spx-color-yellow-500: #ffc738;
$spx-color-yellow: #ffc738;
$spx-color-yellow-400: #ffd56a;
$spx-color-yellow-300: #ffe39c;
$spx-color-orange-700: #aa521f;
$spx-color-orange-600: #d56626;
$spx-color-orange-500: #ff7b2e;
$spx-color-orange: #ff7b2e;
$spx-color-orange-400: #ff9558;
$spx-color-orange-300: #ffb082;
$spx-color-red-700: #a5180c;
$spx-color-red-600: #ce1f0f;
$spx-color-red-500: #f82512;
$spx-color-red: #f82512;
$spx-color-red-400: #f95041;
$spx-color-red-300: #fb7c71;