
.srtTab {
    display: block;
    height: auto;
}

.SectraTabs .tab-content {
    @extend .srtTab;
}

.Viewer .SectraTabs .tab-content {
    @extend .srtTab;
}

.tabItem.tab-pane {
    height: auto;
    margin-top: 20px;
    
    // This will remove horizontal scrollbar.
    width: calc(100% - 11px);
}

.hideTab {
    visibility: hidden;
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
}