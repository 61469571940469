.login-page {

  // Page layout

  font: normal normal normal 15px /1.5 Segoe UI, Open Sans, Arial, Verdana, sans-serif;
  background-color: #FFFFFF;
  min-height: 100vh;
  overflow: hidden;
  position: relative;

  .login-header {
    background: #071326;
  }

  .logo {
    position: absolute;
    top: 1.5em;
    left: 1.5em;
    width: 160px;
    max-width: 33%;
    transform-origin: -25px -25px;
    fill:#CED1D6
  }

  .login-badge {
    display: block;
    height: 24vh;
    min-height: 11em;
    fill:#FFFFFF;
  }

  .login-badge .image {
    width: 164px;
    height: 164px;
    left: 50%;
    margin-left: -82px;
    position: absolute;
    z-index: 1;
    bottom: -55px;
    fill:#FFFFFF
  }

  .pattern {
    position: absolute;
    width: 100%;
    height: 50em;
    bottom: 0;
    left: 0;
    margin: 0 0 1px 0;
    overflow: hidden;
    fill:#FFFFFF
  }

  .login-svg {
    width: 90em;
    height: 90em;
    position: absolute;
    left: 50%;
    margin-left: -45em;
  }

  .login-main {
    background-color: #FFFFFF;
    margin-bottom: 10em;
  }

  .login-content {
    max-width: 640px;
    margin: 0 auto;
    text-align: center;
  }

  a.startbutton {
    display: inline-block;
    font-weight: 600;
    font-size: 1.3em;
    line-height: 2.5em;
    margin: 2em 0;
    padding: 0 2em;
    color: #FFFFFF;
    background-color: #3C73BB;
    border-radius: 3px;
  }

  .login-footer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    min-height: 8em;
    padding: 0 1em 2em 1em;
    color: rgba(255, 255, 255, .6);
    background-color: #071326;
  }

  // Text styling and placement

  h1 {
    font-weight: 100;
    font-size: 1.7em;
    white-space: nowrap;
    color: #808080;
    padding-top: 4.8em;
    margin-top: 0;
  }

  h1, h2, p, div, dd, dt, dl {
    margin: 0;
    border: 0;
    outline: 0;
    vertical-align: baseline;
    position: relative;
  }

  h2, p, dd, dt {
    padding: 1.5em 1.5em 0 1.5em;
  }

  dd, dt {
    padding-top: 0;
    padding-bottom: 0;
  }

  dt {
    font-weight: 600;
  }

  dd {
    font-size: .9em;
  }

  dd > a:link, dd > a:visited {
    color: inherit;
  }

  .login-footer dl:first-child {
    border-left: none;
  }

  .login-footer dl:last-child {
    border-right: none;
  }

  .login-footer dl {
    float: left;
    min-width: 15em;
    border-left: 1px solid #394251;
    border-right: 1px solid #394251;
    margin: 2em 0 0 -1px;
  }

  .login-footer dt {
    color: #e6e6e6;
  }

  p {
    color: #1A1A1A;
  }

  dl.info dt {
    cursor: pointer;
  }

  dl.info dd {
    position: absolute;
    bottom: 120%;
    left: 50%;
    width: 32em;
    margin-left: -16em;
    padding: 0 0 2em 0;
    color: #1a1a1a;
    background-color: #FFFFFF;
    border: 1px solid #CCCCCC;
    border-radius: 3px;
    box-shadow: 0 8px 8px rgba(0, 0, 0, .5);
    z-index: 3;
  }

  dl.info .closebutton {
    position: absolute;
    font-weight: 800;
    text-align: center;
    top: 0;
    right: 0;
    width: 2em;
    line-height: 2em;
    cursor: pointer;
    background-color: transparent;
  }

  dl.info dd:before {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -12px;
    border-style: solid;
    border-color: #FFFFFF transparent transparent transparent;
    border-width: 12px 12px 0 12px;
  }

  .togglebutton {
    background-color: #071326;
  }

  .chevron {
    margin-right: .5em;
    border-style: solid;
    border-width: 0.25em 0.25em 0 0;
    content: '';
    display: inline-block;
    height: 0.63em;
    position: relative;
    top: 0.4em;
    vertical-align: top;
    width: 0.63em;
    transform: rotate(45deg);
  }

  ul li {
    padding-top: 1em;
  }
}