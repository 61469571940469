@import '../../BootstrapSectra/Resources/spx-colors';

.SectraSummary{
  padding: 10px;
  line-height: 1.4;
  white-space: pre-wrap;
}

.SectraSummary.spacing {
  margin-top: 1em;
} 

.summaryInconsistencyWarning {
  color: $spx-color-red-500;
  margin: 0 0 .3em 0;
}

.SectraSummary {
  background-color: #fff;
  color: #191919;
  border: 1px solid $spx-color-silver-700;
  -ms-border-radius: 3px;
  border-radius: 3px;
  min-height: 40px;
  line-height: initial;
}

.theme-dark .SectraSummary {
  background-color: $spx-color-asphalt-350;
  color: $spx-color-silver-550;
  border: none;
  -ms-border-radius: 3px;
  border-radius: 3px;
}

.srt-container .textarea.summary-edit {
  font-size: 10pt;
  line-height: initial;
  padding: 10px;
  margin: -1px 0 9px -1px;
}