.guide {
    --horizontal-border-color: rgba(0, 0, 0, .2);
}

.theme-dark .guide {
    --horizontal-border-color: rgba(255, 255, 255, .2);
}

.guide {
    position: absolute;
    right: 0.5em;
    bottom: 0.5em;
    width: min(80ch, 50vw);
    height: 40vh;
    z-index: 1;

    display: grid;
    grid-template:
        "header" max-content
        "content" 1fr
        "footer" max-content;
    padding: 1em;
    gap: 1em;

    & .header {
        margin: 0;
    }

    & .content {
        font-size: 1.25em;
        overflow-y: auto;
        line-height: 1.75;
    }

    & .footer {
        display: flex;
        flex-direction: row;
        gap: 0.5em;

        & .flex-grow {
            flex-grow: 1;
        }
    }
}

.guide-highlight-element-wrapper {
    position: absolute;
    inset: 0;
    overflow: hidden;
    pointer-events: none;
}

.guide-highlight-element {
    position: absolute;

    top: var(--y);
    left: var(--x);
    width: var(--width);
    height: var(--height);


    &::after {
        content: "";
        position: absolute;
        inset: -8px -16px;
        --size: max(100vw, 100vh);

        margin: calc(-1 * var(--size));
        border: var(--size) solid #0006;

        opacity: 0;
        filter: blur(20px);
        transition: opacity 250ms, filter 250ms;
    }

    &.focus::after {
        opacity: 1;
        filter: blur(2px);
    }


    &.highlight::before {
        content: "";
        position: absolute;
        inset: -2px;
        border: 2px solid chartreuse;


        animation-duration: 1.5s;
        animation-name: guide-highlight-element-animation;
        animation-iteration-count: infinite;
        animation-direction: alternate;
    }

    &.arrow-left::before,
    &.arrow-top::before,
    &.arrow-right::before,
    &.arrow-bottom::before {
        --size: 4px;

        content: "";
        position: absolute;

        border: solid chartreuse;
        border-width: 0 3px 3px 0;

        display: block;
        width: 0;
        height: 0;
        padding: var(--size);

        translate: 0px -50%;

        animation-duration: 1s;
        animation-name: guide-bob-element-animation;
        animation-iteration-count: infinite;
        animation-direction: alternate;
    }

    &.arrow-left::before,
    &.arrow-right::before {
        top: 50%;

        --initial-x: 0px;
        --initial-y: -50%;
    }

    &.arrow-top::before,
    &.arrow-bottom::before {
        left: 50%;
        --initial-x: -50%;
        --initial-y: 0px;
    }

    &.arrow-left::before {
        left: calc(var(--size) * -2);
        transform: rotate(-45deg);
        --offset-x: -8px;
    }

    &.arrow-top::before {
        top: calc(var(--size) * -2);
        transform: rotate(45deg);
        --offset-y: -8px;
    }

    &.arrow-right::before {
        right: 0px;
        transform: rotate(135deg);
        --offset-x: 8px;
    }

    &.arrow-bottom::before {
        bottom: calc(var(--size) * -2);
        transform: rotate(-135deg);
        --offset-y: -8px;
    }

}

@keyframes guide-highlight-element-animation {
    0% {
        border-color: white;
        scale: 1;
    }

    100% {
        border-color: chartreuse;
        scale: 1.025;
    }
}

@keyframes guide-bob-element-animation {
    0% {
        border-color: white;
        translate: var(--initial-x) var(--initial-y);
    }

    100% {
        border-color: chartreuse;
        translate: var(--offset-x, var(--initial-x)) var(--offset-y, var(--initial-y));
    }
}