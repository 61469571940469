.preview-template-container {
    margin: 0 auto;
    padding: 0 5px;
    max-width: 610px;

    > .preview_toolbox {
        padding: 10px 15px;
        border-bottom: solid 1px rgba(0, 0, 0, 0.3);

        button {
            all: unset;
            cursor: pointer;
        }
    }
}

.theme-dark .preview-template-container > .toolbox {
    border-color: rgba(255, 255, 255, 0.3);
}