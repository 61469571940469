@import '../../BootstrapSectra/Resources/spx-colors';

.CodeMirror-dialog{position:absolute;left:0;right:0;background:rgba(204,204,204,0.5);z-index:15000;padding:.1em .8em;overflow:hidden;color:inherit}
.CodeMirror-dialog-top{border-bottom:1px solid #eee;top:0}
.CodeMirror-dialog-bottom{border-top:1px solid #eee;bottom:0}
.CodeMirror-dialog input{border:none;outline:0;background:0 0;width:20em;color:inherit;font-family:monospace}
.CodeMirror-dialog button{font-size:70%}

.Editor {
    flex: 1 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 5px;
}

.Editor .EditorHeader {
    display: table;
    width: 100%;
    margin-bottom: .3rem;
}

.Editor .EditorHeader > span {
    display: table-cell;
    font-size: 90%;
    vertical-align: bottom;
    opacity: .8;
}

.Editor .EditorHeader > div:last-child {
    display: table-cell;
    text-align: right;
    white-space: nowrap;
}

.Editor .EditorHeader .btn.btn-secondary {
    padding: 2px 6px 3px;
}

.Editor textarea {
    flex: 1 1;
    white-space: nowrap;
    font-family: monospace;
}

.theme-light .Editor {
    background-color: $spx-color-silver-500;
    color: $spx-color-black-500;
}

.theme-dark .Editor {
    background-color: $spx-color-asphalt-400;
    color: $spx-color-white-500;
}

.react-codemirror2 {
    height: 95%;
}

.CodeMirror {
    height: 100% !important;
}

.CodeMirror-hint {
    min-width: 200px;
    max-width: calc(80vw - 150px);
    white-space: pre-line;
    margin: 1px 0 2px 0;
    padding: 1px 3px;
    font-weight: bold;
}

.CodeMirror-hints .CodeMirror-hint .hint-description {
    opacity: .7;
    display: inline;
    vertical-align: top;
    font-weight: normal;
}

.CodeMirror-hints .CodeMirror-hint .hint-description:before {
    content: " - ";
    opacity: .5;
    white-space: pre;
}

.CodeMirror-hints .CodeMirror-hint.CodeMirror-hint-active {
    background: rgba(60, 115, 187, 9);
    color: white;
}

.codemirror-highlighted {
    background-color: #E6DB74;
    color: black;
}

.cm-s-lucario span.cm-string.codemirror-highlighted, .cm-s-lucario span.cm-string-2.codemirror-highlighted {
    color: black;
}