@import './BootstrapSectra/Resources/spx-colors';

.theme-dark .nav-tabs,
.theme-dark .dropdown-divider {
    border-color: $spx-color-asphalt-325;
    margin: .25em 0;
}


.theme-light .nav-tabs,
.theme-light .dropdown-divider {
    border-color: $spx-color-silver-650;
    margin: .25em 0;
}


.theme-dark .nav-tabs li.active a {
    color: $spx-color-white-500;
    border: 1px solid $spx-color-asphalt-325;
    border-bottom: 1px solid $spx-color-asphalt-425;
    background-color: inherit;
}


.theme-light .nav-tabs li.active a {
    color: $spx-color-black-500;
    border: 1px solid $spx-color-silver-650;
    border-bottom: 1px solid $spx-color-silver-550;
    background-color: inherit;
}


.theme-dark .nav-tabs li a {
    color: $spx-color-white-500;
}


.theme-light .nav-tabs a {
    color: $spx-color-black-500;
}


.theme-dark .nav-tabs li a:hover {
    color: $spx-color-white-500;
    border: 1px solid $spx-color-blue-400;
    background-color: $spx-color-asphalt-275;
}


.theme-light .nav-tabs li a:hover {
    color: $spx-color-black-500;
    border: 1px solid $spx-color-blue-150;
    background-color: $spx-color-silver-475;
}
